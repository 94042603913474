const openForHire = {
    position: 'Open for Hire',
    technologies: '',
    tab: 'Remote',
    company: 'Remote',
    workStart: 'May. 2024',
    workUntil: '<span class="present-work">Present</span>',
    des: 'Currently I am using the downtime to upgrade the skills that I felt I was missing on my previous jobs, and relax, so I can be prepared properly for my next career adventure! If you want to collaborate or hire me, feel free to contact me at <a href="mailto:jane@zdravevski.pro">jane@zdravevski.pro</a> or my <a href="/contact">Contact Page</a>',
    url: '/contact',
    target: '_self',
};

// uncomment this when you adding new position, because of the span present-work styles (to not forget)

const cto = {
    position: 'Chief Technology Officer',
    technologies: 'VueJS, NuxtJS, Svelte, CSS, HTML, SCSS/SASS, NodeJS/ExpressJS, Gitea, DevOps, Task Management, Product Design, Team Management',
    tab: 'Kimicom',
    company: 'Kimicom',
    workStart: 'Nov. 2023',
    workUntil: 'May. 2024',
    des: "As CTO I was responsible for the technology decisions on new and old projects & SaaS's, tasks management, coordinating the tech team in the company, serve as link between management & tech team, while also coding, deploying, quality assurance, etc",
    url: 'https://kimicom.de',
    target: '_blank',
};

const kimicom = {
    position: 'Full-Stack Developer',
    technologies: 'VueJS, NuxtJS, Svelte, CSS, HTML, SCSS/SASS, NodeJS/ExpressJS',
    tab: 'Kimicom',
    company: 'Kimicom',
    workStart: 'Aug. 2023',
    workUntil: 'Nov. 2023',
    des: "As <b>Full-Stack Developer</b> at <a href='https://kimicom.de/' target='_blank'>Kimicom</a> my obligations so far were to create their main website, maintain it, also help solve problems in old projects, as well as work on creating & maintaining new projects.",
    url: 'https://kimicom.de',
    target: '_blank',
};

const game = {
    position: 'Problem Solver',
    technologies: 'Unreal Engine 5, ElectronJS, Nuxt & Vue, Firebase, Python',
    tab: 'CrystalPops',
    company: 'CrystalPops',
    workStart: 'Nov. 2021',
    workUntil: 'Mar. 2022',
    des: "As <b>Problem Solver</b> and <b>Full-Stack Developer</b> at <a href='https://www.crystalpops.com/' target='_blank'>Crystal Pops</a> my responsibilities were to create a game launcher, website, and solve lots of logical issues around the game. Besides that, I had to also figure out how all of this will work together, test, build, deploy, etc.",
    url: 'https://www.crystalpops.com/',
    target: '_blank',
};

const nfts = {
    position: 'Scripter',
    technologies: 'Blender, Python',
    tab: 'CrystalPops',
    company: 'CrystalNFTs',
    workStart: 'Sep. 2021',
    workUntil: 'Nov. 2021',
    des: "<b>Scripter</b> sounds funny, I know, but at this project, the idea was to somehow combine 40-50 3D assets in Blender, and create 10,000 characters out of it, to make an NFT Drop. I didn't knew how any of that works, and after 10-15 minutes research, I figured it can be done with Python. So I made a script.",
    url: 'https://www.niftygateway.com/collections/crystal-pop-10k',
    target: '_blank',
};

const pipes = {
    position: 'Front End Developer',
    technologies: 'Vue, Excel',
    tab: 'Pipes',
    company: 'Pipes',
    workStart: 'December 2020',
    workUntil: 'March 2021',
    des: `We had to create a landing page and admin panel for a client that will calculate some complex formulas for water installation with many variables and parameters.  Since we were only given a few instructions and a couple excel tables with formulas, my job was to extract the formulas, test, integrate that into the codebase and make all look nice and dandy.  I was junior on this project, and my obligations were mostly related to data extraction, creating excel formulas, grunt work around the code, and making the senior on the project job easier.`,
    url: '#',
    target: '_self',
    // certificate: {
    //     link: 'https://drive.google.com/file/d/1p7I8t2maRU5vXJYjdE2-Cw1SiJJOVyYd/view?usp=sharing',
    //     label: 'Show Certificate of Fulfillment',
    // },
};

const helix = {
    position: 'Front End Developer',
    technologies: 'Vue & Nuxt, Apollo (GraphQL), Strapi as hCMS',
    tab: 'Helix',
    company: 'HelixAnimation',
    workStart: 'December 2019',
    workUntil: 'March 2020',
    des: `This project was a complete dynamic website build, using Strapi as headless CMS and GraphQL for querying, VueJS/Nuxt for front-end.  It was a huge endeavor for me, as Junior, since I had very little experience with projects of this size, and it served as a wind in my back for the rest of the success I had in the future.  Most of the part was coded by me, supervised by a Senior that was code-reviewing me and guiding me out of the darkness, occasionally. This project was built 100% custom, no CSS libraries, no pre-made stuff.`,
    url: 'https://helixanimation.com/',
    target: '_blank',
    // certificate: {
    //     link: 'https://drive.google.com/file/d/1p7I8t2maRU5vXJYjdE2-Cw1SiJJOVyYd/view?usp=sharing',
    //     label: 'Show Certificate of Fulfillment',
    // },
};

const youtube = {
    position: 'YouTuber, Streamer, Sound + Video Creator & Editor, Social Manager',
    technologies: 'Adobe Suite, Audacity, OBS, Wordpress, Discord',
    tab: 'YouTube',
    company: 'YouTube',
    workStart: '2015',
    workUntil: '2021',
    des: `I’ve played games my entire life, my friends pushed me to try streaming my gameplay, since I created some advanced strategies of optimizing your gameplay. So I did. I streamed for 12-14 hours a day, for 2 months, until I got Twitch Partner. Then I did the same with YouTube.  Then I worked all that for 5-6 years with breaks, and many freelance projects like voice overs and video edits, on the side.  During this period I also have managed to rank multiple websites first on Google as my projects, including my own (for some popular keywords), managed my social networks as helped couple others.  | <a href="https://www.youtube.com/UmrenTV" target="_blank">YouTube</a> | <a href="https://www.twitch.tv/umren" target="_blank">Twitch</a> | <a href="https://www.discord.gg/umrentv" target="_blank">Discord</a> `,
    url: 'https://youtube.com/umrentv',
    target: '_blank',
    // certificate: {
    //     link: 'https://drive.google.com/file/d/1p7I8t2maRU5vXJYjdE2-Cw1SiJJOVyYd/view?usp=sharing',
    //     label: 'Show Certificate of Fulfillment',
    // },
};

const various = {
    position: 'Freelancer & Personal Projects',
    technologies: 'Wordpress, HTML/CSS/JS, SEO, Google/Facebook Ads, Amazon Associates, AWS Services, Python, Kali/Backtrack Linux & toolset, etc',
    tab: 'Self',
    company: 'Self',
    workStart: '2010',
    workUntil: '<span class="present-work">Present</span>',
    des: `I’ve been working on a PC since I was really young, really, but I have started earning from it around this period from my skills, and that has involved various projects in many areas.  Some of it include: Wordpress developer, Internet Marketer (Amazon, Google Ads, Pre-Launch marketing, JVZoo), SEO optimizations & Ranking Websites (White + Gray hat), Penetration Testing of Web + Apps, Python scripting, etc`,
    url: '#',
    target: '_self',
    // certificate: {
    //     link: 'https://drive.google.com/file/d/1p7I8t2maRU5vXJYjdE2-Cw1SiJJOVyYd/view?usp=sharing',
    //     label: 'Show Certificate of Fulfillment',
    // },
};

export default [openForHire, cto, kimicom, game, nfts, pipes, helix, youtube, various];
